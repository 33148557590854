import { useCallback, useEffect, useState } from "react";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import style from "./styles.module.css";
import newStyle from "./new-style.module.css";
import cn from "classnames";
import classNames from "classnames";
import { inviteUser } from "frontend/services/usersService";
import { signIn, getDirectoryContacts } from "frontend/services/firebaseService";
import tracking from "frontend/tracking";
import Checkbox from "frontend/ui-components/checkbox";
import FormInputField from "frontend/ui-components/form-fields/form-input-field";
import useStateValue from "frontend/state/value";
import { useAtom } from "jotai";
import { useDebounce } from "react-use";
import consts from "shared/consts";
import { colorForIndex } from "shared/datamodel/client-state";
import { inviteContactsSourceAtom } from "state-atoms";
import { z } from "zod";
import PermissionMenuWrapper from "../collaboration/permission-menu-wrapper";
import { Permission } from "shared/datamodel/schemas";

type InviteSourceContact = {
  name: string | null;
  photo: string | null;
  email: string;
};

type InviteSource = {
  type: string;
  title: string;
  icon: string;
  token: string;
  contacts: InviteSourceContact[];
};

function OnboardingInviteTeammates({ onCompleted }: { onCompleted: () => void }) {
  const [emails, setEmails] = useState<Record<string, string>>({});
  const [nonEmptyEmails, setNonEmptyEmails] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([Permission.editor, Permission.editor, Permission.editor]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [validEmails, setValidEmails] = useState(false);
  const [numberOfEmails, setNumberOfEmails] = useState(3);
  const [inviteSource, setInviteSource] = useState<InviteSource | null>(null);
  const [inviteSourceString, setInviteSourceString] = useAtom(inviteContactsSourceAtom);
  const [isSending, setIsSending] = useState(false);
  const [sourceQuery, setSourceQuery] = useState("");
  const debouncedQuery = useDebounce(() => {
    sourceQuery;
  }, 200);

  const [focusedFieldIndex, setFocusedFieldIndex] = useState(0);
  const [, dispatch] = useStateValue();
  const showInviteSources = false;

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, "onboarding_invite_screen");
  }, []);

  useEffect(() => {
    setNonEmptyEmails(Object.values(emails).filter((email) => email.length));
  }, [emails]);

  useEffect(() => {
    setValidEmails(validateFields());
    setNumberOfEmails(Math.max(3, nonEmptyEmails.length + 1));
  }, [nonEmptyEmails]);

  useEffect(() => {
    if (inviteSource?.token) {
      (async function () {
        await fetchContacts(inviteSource.token, debouncedQuery.toString());
      })();
    }
  }, [debouncedQuery]);

  useEffect(() => {
    if (inviteSource) {
      setInviteSourceString(inviteSource.type);
    }
  }, [inviteSource]);

  useEffect(() => {
    autoScrollToBottom();
  }, [numberOfEmails]);

  async function inviteFromGmail() {
    tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "invite_from_gmail_clicked");
    const { token } = await signIn();
    if (token) {
      await fetchContacts(token, "");
    }
  }

  async function fetchContacts(token: string, query: string) {
    if (query) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "search_query_google_contacts");
    }
    const contacts = await getDirectoryContacts(token, query);
    setInviteSource({
      type: "gmail",
      title: "Google",
      icon: "/images/g-logo.png",
      token,
      contacts,
    });
  }

  const inviteClicked = useCallback(
    async (source: string) => {
      if (!validEmails) {
        return;
      }
      tracking.trackEvent(
        consts.TRACKING_CATEGORY.ONBOARDING,
        "invite_contacts_button_clicked",
        source,
        nonEmptyEmails.length
      );
      setIsSending(true);
      try {
        const permissionAlignedIndexes = permissions.filter((_, index) => emails[`field${index}`]);
        const usersPermissions = nonEmptyEmails.reduce((acc, email, index) => {
          acc[email] = permissionAlignedIndexes[index];
          return acc;
        }, {} as Record<string, Permission>);
        const { failed, users } = await inviteUser(dispatch, usersPermissions);
        if (failed.length > 0) {
          tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "invite_contacts_failed", source, failed.length);
        }
        if (users.length > 0) {
          tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "invite_contacts_success", source, users.length);
        }
        onCompleted();
      } catch (error) {
        console.error("failed invite", error);
      } finally {
        setIsSending(false);
      }
    },
    [validEmails, nonEmptyEmails, permissions, emails]
  );

  function skipClicked(source: string) {
    tracking.trackEvent(consts.TRACKING_CATEGORY.ONBOARDING, "skip_invite_button_clicked", source);
    onCompleted();
  }

  function changeEmail(id: string, value: string) {
    setEmails((emails) => ({ ...emails, [id]: value }));
  }

  const validateFields = useCallback(() => {
    if (nonEmptyEmails.length === 0) {
      return false;
    }
    try {
      const nonEmptyEmails: Record<string, string> = {};
      setErrors({});
      for (const [key, value] of Object.entries(emails)) {
        // filter empty values because we allow it
        if (value) {
          nonEmptyEmails[key] = value;
        }
      }
      const validator = z.record(z.string(), z.string().email("Email is not valid"));
      validator.parse(nonEmptyEmails);
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors
          .map((e) => [e.path.join("."), e.message])
          .forEach((o) => {
            const [id, msg] = o;
            setErrors((e) => ({ ...e, [id]: msg }));
          });
      }
      return false;
    }
  }, [nonEmptyEmails]);

  const renderEmails = useCallback(() => {
    return Array.apply(null, Array(numberOfEmails)).map((_, idx) => {
      const id = `field${idx}`;
      return (
        <PermissionMenuWrapper
          usersPermission={permissions[idx] ?? Permission.editor}
          setUsersPermission={(permission: Permission) =>
            setPermissions((permissions) => {
              const newPermissions = [...permissions];
              newPermissions[idx] = permission;
              return newPermissions;
            })
          }
          customDropdownStyle={{
            height: 40,
            width: 103,
            fill: "#fff",
            border: "1px solid #dadce0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
          key={id}
        >
          <div className={newStyle.inputContainer}>
            <FormInputField
              id={id}
              key={id}
              placeholder="Enter email addresses"
              onChange={(value) => changeEmail(id, value)}
              error={errors[id]}
              onBlur={validateFields}
              autoComplete="auto-complete-off"
              onSubmit={() => setFocusedFieldIndex((index) => (index == numberOfEmails - 1 ? 0 : index + 1))}
              autoFocus={idx === focusedFieldIndex}
              disabled={isSending}
              customInputStyle={{ height: 38 }}
            />
          </div>
        </PermissionMenuWrapper>
      );
    });
  }, [numberOfEmails, errors, permissions, validateFields, focusedFieldIndex]);

  function autoScrollToBottom() {
    const element = document.getElementById("emails-list");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  function checkEmail(email: string, checked: boolean) {
    if (checked) {
      setEmails((emails) => ({ ...emails, [email]: email }));
    } else {
      setEmails((emails) => ({ ...emails, [email]: "" }));
    }
  }

  function renderContactImage(contact: InviteSourceContact) {
    if (contact.photo) {
      return <img className={style.contactImage} src={contact.photo} />;
    }

    const emailName = contact.email.split("@")[0];
    const letter = emailName[0].toUpperCase();
    const letterIndex = emailName.charCodeAt(emailName.length - 1);
    const { color } = colorForIndex(letterIndex);

    return (
      <div className={style.contactImage} style={{ background: color }}>
        {letter}
      </div>
    );
  }

  function renderContact(contact: InviteSourceContact) {
    const isChecked = nonEmptyEmails.includes(contact.email);
    return (
      <div key={contact.email} className={style.contactRow} onClick={() => checkEmail(contact.email, !isChecked)}>
        <Checkbox checked={isChecked} onChange={(checked) => checkEmail(contact.email, checked)} isError={false} />
        {renderContactImage(contact)}
        {contact.email} {contact.name}
      </div>
    );
  }

  function renderContacts(contacts: InviteSourceContact[]) {
    if (!contacts.length) {
      return (
        <div className={style.emptyContacts}>
          <span>No results found for the given search criteria.</span>
          <span>Please ensure that you have the necessary permissions to perform this request.</span>
          <span>If the issue persists, please contact your GSuite administrator for assistance.</span>
          <span>
            <a href="#" onClick={() => setInviteSourceString(undefined)}>
              Go back
            </a>{" "}
            to enter emails manually
          </span>
        </div>
      );
    }
    return contacts.map(renderContact);
  }

  function renderSource(source: InviteSource) {
    return (
      <div className={newStyle.container} style={{ gap: 23 }}>
        <div className={style.title}>
          Invite teammates from
          <img src={source.icon} referrerPolicy="no-referrer" />
          {source.title}
        </div>
        <div className={classNames(style.content, style.inviteSourceContent)}>
          <div className={style.searchFieldContainer}>
            <FormInputField id={"search"} placeholder="Search contacts..." autoFocus={true} onChange={setSourceQuery} />
          </div>
          <div className={style.contactsList}>{renderContacts(source.contacts)}</div>
        </div>
        {renderInviteButton(`invite_from_source_${source.type}`)}
      </div>
    );
  }

  function renderInviteButton(source: string) {
    return (
      <div className={newStyle.inviteButtonContainer}>
        <StyledButton
          title="Send Invitations"
          size={ButtonSize.big}
          customStyle={{ width: "14vw", fontSize: "calc(.25em + 0.5vw + 0.5vh)" }}
          loading={isSending}
          loadingTitle={"Sending..."}
          onClick={() => inviteClicked(source)}
          enabled={validEmails}
        />
        <span data-testid="Skip for now" className={newStyle.skipButton} onClick={() => skipClicked(source)}>
          Skip for now
        </span>
      </div>
    );
  }

  if (inviteSource && inviteSourceString === inviteSource.type) {
    return renderSource(inviteSource);
  }

  return (
    <div className={newStyle.container} style={{ gap: 32 }}>
      <div className={newStyle.flexStart}>
        <div className={newStyle.header}>
          <span className={newStyle.title}>Invite people to your account</span>
        </div>
        {showInviteSources && (
          <div className={newStyle.inviteButtons}>
            <span className={newStyle.subtitle}>Invite contacts from:</span>
            <div style={{ width: "166px" }}>
              <SourceButton icon={"/images/g-logo.png"} title={"Google Contacts"} onClick={inviteFromGmail} />
            </div>
          </div>
        )}
        <div id="emails-list" className={newStyle.emailInvite}>
          <span className={newStyle.subtitle}>Send invites</span>
          {renderEmails()}
        </div>
        {renderInviteButton("emails")}
      </div>
    </div>
  );
}

function SourceButton({ icon, title, onClick }: { icon: string; title: string; onClick: () => void }) {
  return (
    <div className={style.sourceButton} onClick={onClick}>
      <div className={style.buttonContainer}>
        <img src={icon} />
      </div>

      <span className={style.buttonTitle}>{title}</span>
    </div>
  );
}

export default function InviteView({ onCompleted }: { onCompleted: () => void }) {
  return (
    <div className={cn(newStyle.onboarding, newStyle.twoColumns)}>
      <div className={newStyle.main}>
        <OnboardingInviteTeammates onCompleted={onCompleted} />
      </div>

      <div className={newStyle.sidebar}>
        <div className={newStyle.imageContainer}>
          <img className={newStyle.image} src={"/images/onboarding/orgchart_layout_screen/canvas-background.png"} />
          <img className={newStyle.image} src={"/images/onboarding/invite-image.png"} />
        </div>
      </div>
    </div>
  );
}
