import { getAllUsers, getPendingUsersPermissions, inviteUser as internalInviteUser } from "frontend/api";
import { Action, DispatchEvent } from "frontend/state";
import { Permission } from "shared/datamodel/schemas/user";

export async function reloadUsers(dispatch: (e: DispatchEvent) => void) {
  try {
    dispatch({ type: Action.LoadingUsers, payload: null });
    const users = await getAllUsers();
    const pendingUserPermissions = await getPendingUsersPermissions();
    dispatch({ type: Action.UpdatedUsers, payload: { users, pendingUserPermissions } });
  } catch {
    dispatch({ type: Action.FailedLoadingUsers, payload: null });
  }
}

export async function inviteUser(dispatch: any, usersPermissions: Record<string, Permission> , canvasName?: string) {
  const response = await internalInviteUser(usersPermissions, canvasName);
  const pendingUsersPermissions = await getPendingUsersPermissions();
  dispatch({ type: Action.UpdatedUsersSubset, payload: { users: response.users, pendingUsersPermissions } });
  return response;
}
