import { useEffect } from "react";
import style from "./mobile-block-screen.module.css";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export default function MobileBlockError() {
  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, "Mobile_Block_Error");
  }, []);

  return (
    <div>
      <div className={style.mobileContainer}>
        <img src={"/images/logo-with-frame.png"} className={style.logo} />
        <span className={style.mobileContentTitle}>Mobile experience coming soon!</span>
        <span className={style.mobileContentBody}>
          For now, to unlock the full power of WorkCanvas go to your desktop browser
        </span>
        <img src={"/images/mobile-block-screen.png"} className={style.bottomContainer} />
      </div>
    </div>
  );
}
