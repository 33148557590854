import style from "./styles.module.css";
import { useCallback, useEffect, useState } from "react";
import classNames from "classnames/bind";
import StyledButton, { ButtonSize } from "../../ui-components/styled-button";
import tracking from "../../tracking";
import consts from "shared/consts";

export default function OnboardingTeamSize({ onCompleted }: { onCompleted: (type: 'myself'|'team') => void }) {
  const [ selectedSize, setSelectedSize ] = useState<'myself'|'team'| null>(null);

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.PAGE_VIEWS, "onboarding_team_size");
  }, []);

  const continueClicked = useCallback(() => {
    if (selectedSize) {
      onCompleted(selectedSize)
    }
  }, [ selectedSize ]);

  return (
      <div className={ style.container }>
        <span className={ style.title }>How do you plan to use WorkCanvas?</span>
        <div className={ style.content }>
          <div className={ style.blocks }>
            <div
              className={ classNames(style.block, { [style.selected]: selectedSize === "myself" }) }
              onClick={ () => setSelectedSize("myself") }
            >
              <div className={ style.imageContainer }>
                <img src="/images/onboarding-team-size-me.png" alt=""/>
              </div>
              <div className={ style.blockTitle }>
                <div className={ classNames(style.radio, { [style.selected]: selectedSize === "myself" }) }/>
                <span>For myself</span>
              </div>
            </div>
            <div
                className={ classNames(style.block, { [style.selected]: selectedSize === "team" }) }
                onClick={ () => setSelectedSize("team") }
            >
              <div className={ style.imageContainer }>
                <img src="/images/onboarding-team-size-team.png"/>
              </div>
              <div className={ style.blockTitle }>
                <div className={ classNames(style.radio, { [style.selected]: selectedSize === "team" }) }/>
                <span>With my team</span>
              </div>
            </div>
          </div>
        </div>
        <StyledButton
            title="Continue"
            onClick={ continueClicked }
            size={ ButtonSize.big }
            customStyle={ { width: 212 } }
            enabled={ !!selectedSize }
        />
      </div>
  );
}
